@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');
@import url('https://fonts.googleapis.com/css2?family=El+Messiri&display=swap');


.hero {
    height:70vh;
    width: 100%;
    background-color: rgba(59, 39, 0, 0.527);
    z-index: 5555;
}

video {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;

  }

.hero .hero-container {
    width: 100%;
    bottom: 20%;
    position: absolute;
    text-align: center;
    padding: 1rem;
}

.hero-logo {
    text-align: center;
}

.hero .hero-container h1 {
    color: rgb(224, 216, 207);
    letter-spacing: 3px;
    font-size: 2rem;
    font-weight: bolder;
    font-size: 4.5rem;
    font-family: 'Cabin', sans-serif;  
  }
  

.hero h2 {
    font-size: 2rem;
    color: rgb(224, 216, 207);
    font-family: 'El Messiri', sans-serif;
    letter-spacing: 1px;
}

.hero h3 {
    font-size: 1.5rem;
    color: rgb(224, 216, 207);
    font-family: 'El Messiri', sans-serif;
    padding: .5rem 0;
    letter-spacing: 1px;
}


.hero .hero-btn button {
    cursor: pointer;
    color: #1b1c1d;
    background-color: #ffffff;
    border: none;
    padding: .8rem 2.5rem;
    border-radius: 5px;
    font-size: 14px;
}



/* MEDIA QUERIES */

@media screen and (max-width: 600px) {

    .hero h2 {
        font-size: 15px;
    }

    .hero .hero-container h1 {
    font-size: 2.5rem;
    }

    .hero h3 {
        font-size: 1rem;
    }


}



@media only screen and (max-height: 670px) and (orientation: landscape) {
    .hero .hero-container {
        bottom: 0%;
    }

    .hero .hero-container h1 {
        font-size: 2rem;
        }

    .hero h2 {
        font-size: 1rem;
    }

    .hero h3 {
        font-size: 1rem;
    }
    
    }