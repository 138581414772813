@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.PanicDisorder-section-header {
    width: auto;
    padding: 20px;
    text-align: center;
    margin: 2rem auto;
}

.PanicDisorder-section-header h1 {
    font-family: 'Montserrat', sans-serif;
    text-align: left;
}

.PanicDisorder-section-header p {
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
}

/* MEDIA QUERIES */

@media screen and (max-width:700px) {

  .PanicDisorder-section-header h1 {
      text-align: center;
  }
  
  }
