
.footer {
    width: 100%;
    height: auto;
    position: relative;
    background-color: rgb(4, 52, 80);  
  }
  
  .footer:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    opacity: .02;
  }
  


.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
}


.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.footer .top .logo-footer {
    width: 200px;
    height: auto;
}

.footer .top .logo-footer img {
    width: 100%;
    height: auto;
}

.footer .top .icon {
    font-size: 3rem;
    color: rgb(226, 233, 235);
    cursor: pointer;
    margin: auto;
}

.col {
    width: max-content;
    padding: 0 1rem;
}

.footer .col-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-around;
    padding: 1rem;
    width: 100%;
}

.footer h3 {
    margin: 1rem 0 2rem 0;
    text-transform: uppercase;
    text-align: center;
    color: var(--secondary-color);
}


.footer p {
    margin: .5rem 0;
    color: rgb(226, 233, 235);
    text-align: center;

}

.copyright p {
    color: rgb(226, 233, 235);
    text-transform: uppercase;
    text-align: center;
}

.certification {
    width: 100%;
}

.dhcs {
    width: 100%;
    text-align: center;

}

.joint {
    width: 100%;
    padding: 1rem;
}


.certification h3 {
    text-align: center;
    font-size: .8rem;
}

.certification p {
    text-align: center;
    color: white;
}

.dhcsImage {
    width: 130px;
    margin: auto;
}

.dhcsImage img{
    width: 100%;
}

.jointImage {
    width: 130px;
    margin: auto;
}

.jointImage img{
    width: 100%;
}

.certification a {
    color: white;
    font-style: italic;
}

.certification a:hover {
    text-align: center;
    color: rgb(10, 208, 235);
}



@media screen and (max-width: 940px) {
    .footer {
        height: 100%;
    }





}


