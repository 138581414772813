@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.PanicDisorder {
    width: 100%;
    height: 50vh;
    background: rgba(0, 0, 0, 0.486);
    position: relative;
  }
  
  .PanicDisorder:before {
    content: '';
    position: absolute;
    background: url('../../assets/mental-health-submenu-bg-min.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.PanicDisorder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.PanicDisorder .PanicDisorder-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}

.PanicDisorder h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
}

.PanicDisorder p {
    text-align: center;
    color: rgba(255, 255, 255, 0.75);

}

/* MEDIA QUERIES */

@media screen and (max-width:700px) {

.PanicDisorder h1 {
    font-size: 2rem;
}

}