@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.BipolarDisorder-section-header {
    width: auto;
    padding: 20px;
    text-align: center;
    margin: 2rem auto;
}

.BipolarDisorder-section-header h1 {
    font-family: 'Montserrat', sans-serif;
    text-align: left;
}

.BipolarDisorder-section-header p {
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
}


/* MEDIA QUERIES */

@media screen and (max-width:700px) {

  .BipolarDisorder-section-header h1 {
      font-size: 1.5rem;
      text-align: center;
  }
  
  }