@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.anxiety-section-header {
    width: auto;
    padding: 20px;
    text-align: center;
    margin: 2rem auto;
}

.anxiety-section-header h1 {
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    color: rgb(68, 67, 67);
}

.anxiety-section-header p {
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
}

@media screen and (max-width:700px) {
  .anxiety-section-header h1 {
    text-align: center;
  }
}
