@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.Schizophrenia-section-header {
    width: auto;
    padding: 20px;
    text-align: center;
    margin: 2rem auto;
}

.Schizophrenia-section-header h1 {
    font-family: 'Montserrat', sans-serif;
    text-align: left;
}

.Schizophrenia-section-header p {
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
}


@media screen and (max-width:700px) {
  .Schizophrenia-section-header h1 {
    text-align: center;
    font-size: 1.5rem;
  }
}