@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


.InsuranceSection {
    width: 100%;
    height: auto;
    position: relative;
    /* background: rgb(252, 224, 132); */
  }
  
  .InsuranceSection:before {
    content: '';
    position: absolute;
    /* background: url('../../assets/section-bg.webp') no-repeat center center/cover; */
    height: 100%;
    width: 100%;
    opacity: .06;
  }


  .insurance-section-content {
    padding: 3rem 0;
  }

  .insurance-section-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
  }

  .insurance-section-left h1 {
    padding: 1rem 0;
    font-family: 'Bacasime Antique', serif;
    font-size: 1.2rem;
  }

  .insurance-section-left p {
    text-align: justify;
  }

  .insurance-section-left {
    width: 50%;
  }

  .insurance-section-right {
    width: 250px;
    height: 100%;
  }

  .insurance-section-right img {
    width: 100%;
    height: 100%;
  }

.insurance-section-header {
    text-align: center;
    padding: 25px;
}

.insurance-section-header p {
    padding: 1rem 0;
    font-family: 'Montserrat', sans-serif;
}

.insurance-section-thumbnails {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: 5rem 0;
    margin: 1rem 0;
    margin: auto;
}

.insurance-thumbnail {
    width: 120px;
}

.insurance-thumbnail img {
    width: 100%;
}



@media  screen and (max-width:940px) {

    .insurance-section-left {
        width: 100%;
      }
    
    .insurance-section-header h1 {
        font-size: 1.5rem;
        padding: 1rem 0;
    }


    .insurance-section-header p {
        font-size: 14px;
    }

    .insurance-thumbnail {
        width: 100px;
    }

}






