@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.about-container {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .about-container:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.05;
  }




.about-content {
    display: flex;
    gap: 1rem;
    justify-content: center;
    padding: 3rem 1rem;
}

.about-content-left {
    width: 400px;
    text-align: center;
    margin: auto;
}

.about-content-left img {
    width: 100%;
    border-radius: 1rem;
}

.about-content-right {
    width: 600px;
    margin: auto;
}


.about-content-right h2 {
    font-size: 1.5rem;
}

.about-content-right h1 {
    text-transform: uppercase;
    color: #fd9b2f;
    font-size: 1rem;
    letter-spacing: 8px; 
}

.about-content-right p {
    text-align: justify;
}


@media screen and (max-width:940px) {

    .about-content-right h1, .about-content-right h2 {
        text-align: center;
    }

    .about-content-right h2 {
        font-size: 1rem;
    }


    .about-content {
        flex-wrap: wrap-reverse;
    }

    .about-content-left {
        width: 100%;
    }

    .about-content-right {
        width: 100%;
    }

}
