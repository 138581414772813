@import url('https://fonts.googleapis.com/css2?family=Varela&display=swap');

.navbar {
    width: 100%;
    z-index: 10;
    background: #1b262c;
    position: relative;
    text-align: center;
}



.nav-box {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
}


.nav-box ul {
  margin: auto 0;
}

.navbar-logo {
  width: 150px;
  z-index: 2;
  padding: 0 1rem;
}


.navbar-logo img {
  width: 100%;
  height: 100%;
}

.navbar .icon {
  font-size: 1.4rem;
  color: white;
}

.navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
}

.hamburger {
    display: none;
    padding: 1rem;
    margin: auto 0;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
    padding: 1rem;
}

.navbar li {
    width: max-content;
    padding: .3rem .4rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

.navbar li a {
  color: rgb(243, 242, 238);
  font-family: 'Varela', sans-serif;  
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;

}

.navbar li a:hover {
  color: rgb(224, 132, 13) !important;
  font-weight: 500;
}

  .menu-item .sub__menus li a {
    color: #f8f5f5;
    font-size: 13px;
    font-weight: 100;
  }

  .menu-item .sub__menus li a:hover {
    color: rgb(224, 132, 13) !important;
    font-weight: 500;
  }

  li .submenu-header {
    font-size: 14px;
    color: rgb(36, 131, 27);
    font-weight: bolder;
    text-decoration: underline;
    cursor: pointer;
  }


/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: #1B262C;
    width: auto;
    padding: 2px 0 0 0;
    margin-top: 6px;
    z-index: 1000;
    padding: .5rem 0;
  }



  /* -----------------------------------------------------> DROPDOWN ARROW */


  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    color: #eaeef1;
    padding-top: 5px;
  }


.logo-hide {
  display: none;
}

@media screen and (max-width: 940px) {


  .logo-hide {
    display: block;
    padding: .7rem .5rem;
  }
  
  .logo-hide2 {
    display: none;
  }

  .nav-box {
    justify-content: space-between;
}

    .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 75%;
        height: 81.5vh;
        top: 0;
        left:-100%;
        align-items: center;
        justify-content: center;
        background: var(--primary-color);
        transition: 0.5s ease-in;
        color: white;
    }


    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1rem;
    }

    .navbar button {
        font-size: 1.8rem;
    }

    .menu-item .sub__menus li a {
        font-size: 14px;
      }

      .sub__menus {
        width: 100%;
        left: 0;
      }

      
      .sub__menus li {
        width: 100%;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.185);
      }





.sub__menus__arrows svg, .sub__menus__arrows__full svg {
  top: 15px;
}


}

/* 
@media screen and (max-width: 600px) {
  .sub__menus {
    left: -3rem;
  }
} */