.iop-section {
    width: 100%;
    height: auto;
    position: relative;
    background: rgba(29, 28, 29, 0);
  }
  
  .iop-section:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    opacity: .03;
  }

  .iop-section-content {
    padding: 1rem;
  }

.iop-section-header {
    text-align: justify;
    padding: 3rem 0;
}

.iop-section-header h1 {
    padding: .5rem 0;
    font-weight: 300;
}

.iop-section-header h2 {
    font-weight: 300;
    font-size: 1rem;
    text-align: left;
}

.iop-section-header p {
    padding: .5rem 0;
    text-align: justify;
}

.iop-section-flex {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding-top: 3rem;   
}

.iop-section-flex-left {
    width: 100%;
    margin: auto;
}

.iop-section-flex-right {
    width: 700px;
    height: auto;
    margin: auto;
}

.iop-section-flex-right img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.iop-section-flex h1 {
    padding: .5rem 0;
    font-size: 1rem;
    font-weight: 300;
}

.iop-section-flex h2 {
    padding: .5rem 0;
    font-size: .8rem;
}

.iop-section-flex p {
    text-align: justify;
}

@media screen and (max-width:940px) {
    .iop-section-flex {
        flex-wrap: wrap-reverse;  
    }

    .iop-section-header h1 {
        text-align: center;
        font-size: 1.2rem;
    }


}