.component3 {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .component3:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.05;
  }


.component3-content {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    gap: .5rem;
    padding: 5rem 1rem;
}

.c3-left {
    width: 60%;
    margin: auto;
}

.c3-left p {
    font-size: 14px;
    text-align: justify;
}

.c3-left h1 {
    font-size: 3rem;
    color: var(--primary-color);
    text-align: left;
    text-transform: uppercase;
    font-family: 'Bacasime Antique', serif;
}

.c3-left h5 {
    text-align: left;
    padding: 1rem 0;
}

.c3-left h2 {
    font-size: 1rem;
    color: rgb(198, 134, 24);
    padding: .5rem 0;
    text-align: left;
}

.c3-right {
    width: 70%;
    margin: auto;
}

.c3-right-img {
    width: 100%;
    margin: auto;
}

.c3-right-img img {
    width: 100%;
    border-radius: 5px;
}


@media  screen and (max-width:940px) {

    .component3-content {
        flex-wrap: wrap;
    }
    
    .c3-left {
        width: 100%;
    }
    
    .c3-right {
        width: 100%;
    }

    .c3-left h1 {
        text-align: center;
    }

    .c3-left h2 {
        text-align: center;
    }

    .c3-left h5 {
        text-align: center;
    }



}


